import React, { useContext, useEffect, useState, useRef } from 'react';
import {
	Alert,
	AlertTitle,
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Card,
	Container,
	Button,
	Grid,
	CircularProgress,
	Typography,
	Step,
	Stepper,
	StepButton,
	Switch,
	FormHelperText
} from '@mui/material';
import VideoRecorder from 'react-video-recorder';
import SuspenseLoader from 'src/components/SuspenseLoader';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ButtonWrapper from '../../../components/OnboardingButtonWrapper';
import { OnBoardContext } from '../context';
import VideoInterviewService from '../services/videoInterviewService';
import VideoActions from './video-utils/video-actions';
import ErrorView from './video-utils/error-view';
import VideoInterviewHeader from './video-utils/header';
import JobService from 'src/content/jobboard/services/jobService';
import { JobVideoInterviewPrivateDays } from 'src/content/jobboard/constants';
import { useNavigate } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import '../onboarding.css'
import ErrorIcon from '@mui/icons-material/Error';


const VideoInterview = ({ showNavButtons, openSnack, jobId, job }) => {
	const navigate = useNavigate();
	const { basic_info } = useAuth()
	const { addStep, subStep, setCompletedUpto } = useContext(OnBoardContext);
	const [questions, setQuestions] = useState([]);
	const [openModal, setOpenModal] = useState(true); // State to handle the modal visibility
	const [currQIdx, setCurrQIdx] = useState(0);
	const [questionsDone, setQuestionsDone] = useState(0); //  tells us about the answers saved in server | 0 indexed
	const [showSave, setShowSave] = useState(false);
	const [videoBlob, setVideoBlob] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [allAnswers, setAllAnswers] = useState(jobId ? [] : (basic_info?.video_interview_answers || []));
	const [answeredQIds, setAnsweredQIds] = useState([])
	const [saving, setSaving] = useState(false);
	const [focusModeOn, setFocusModeOn] = useState(false); // is true if video is recording
	const [skipBtnVariant, setSkipBtnVariant] = useState('outlined');
	const [jobVIisPublic, setJobVIisPublic] = useState(false);
	const [recordingAgain, setRecordingAgain] = useState(false);
	const skipRef = useRef();
	const currQuestionId = questions.length > currQIdx && questions[currQIdx].id
	const prevAnsExist = answeredQIds.includes(currQuestionId)
	const currQAnsId = answeredQIds.indexOf(currQuestionId)
	const uploadingPrevAns = prevAnsExist && !allAnswers[currQAnsId].video_url
	const showPrevAns = prevAnsExist && !recordingAgain
	const allAnswered = allAnswers?.length == questions?.length

	const skipNext = async () => {
		await VideoInterviewService.skipVideoInterview();
		addStep();
		setCompletedUpto(7);
	};

	useEffect(() => {
		setAnsweredQIds(allAnswers.map((v) => v.video_interview_question_id))
	}, [allAnswers])

	useEffect(() => {
		setTimeout(() => setShowSave(false), 200);
	}, [currQIdx, questionsDone]);

	const fetchAndSetAnswers = async () => {
		const response = jobId ? await JobService.getAllAnswers(jobId, basic_info.user_id) : await VideoInterviewService.getAllAnswers();
		let answers;
		if(!response){
			setAllAnswers([])
			return []
		}
		if (Array.isArray(response)) answers = response
		else if ('answers' in response) answers = response.answers
		else answers = response.result.answers
		setAllAnswers(answers)
		return answers
	}

	const init = async () => {
		const localQuestions = jobId ? await JobService.getInterviewQuestions(jobId) : await VideoInterviewService.getAllQuestions();
		setQuestions(localQuestions);
		const answers = await fetchAndSetAnswers()
		const localAnsweredQIds = answers?.map((v) => v.video_interview_question_id) || [];
		setIsLoading(false)
		if (localAnsweredQIds?.length === questions?.length) {
			setCurrQIdx(0);
			return;
		}
		setQuestionsDone(localAnsweredQIds.length - 1);
		for (let i = 0; i < localQuestions.length; i++) {
			const question = localQuestions[i]
			if (!localAnsweredQIds.includes(question.id)) {
				setCurrQIdx(i);
				return;
			}
		}
		setCurrQIdx(0);
	};

	useEffect(init, []);

	const onVideoRecordDone = (tempVideoBlob) => {
		setVideoBlob(tempVideoBlob);
		setShowSave(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const saveVideo = async () => {
		setSaving(true);
		var formData = new FormData();
		var videoFile = new File([videoBlob], 'video_answer_' + (currQIdx + 1), { type: 'video/mp4' });

		const questionId = questions[currQIdx].id; // Extract question_id from the questions array using currQuestion index
		const questionNo = questions[currQIdx].question_no;

		formData.append('question_id', questionId);
		formData.append('video', videoFile, videoFile.name);

		try {
			await VideoInterviewService.saveAnswer(formData);
			setShowSave(false);
			openSnack('success');
			// setQuestionsDoneList((prevState) => [...prevState, questionNo]);
			setQuestionsDone(answeredQIds.length - 1);
			// Check if currQuestion is the last question, if so reset to 0, otherwise increment
			setCurrQIdx(currQIdx + 1 >= questions.length ? 0 : currQIdx + 1);
			setRecordingAgain(false)
			await fetchAndSetAnswers()
		} catch (error) {
			console.error('Failed to save video', error);
			openSnack('error');
		} finally {
			setSaving(false);
		}
	};

	const useWidth = () => {
		const [width, setWidth] = React.useState(window.innerWidth);
		React.useEffect(() => {
			const onResize = () => {
				setWidth(window.innerWidth);
			};
			window.addEventListener('resize', onResize);
			return () => window.removeEventListener('resize', onResize);
		}, []);
		return width;
	};

	const changeQuestion = (goToQues) => {
		if (focusModeOn) return;
		if (goToQues === questions.length) {
			if (!showNavButtons) {
				setCurrQIdx(goToQues % questions.length);
				return;
			}
			const blinkRate = 300;
			skipRef.current.focus();
			setSkipBtnVariant('contained');
			setTimeout(() => {
				setSkipBtnVariant('outlined');
				setTimeout(() => {
					setSkipBtnVariant('contained');
					setTimeout(() => setSkipBtnVariant('outlined'), blinkRate);
				}, blinkRate);
			}, blinkRate);
			return;
		}
		setCurrQIdx(goToQues % questions.length);
		setRecordingAgain(false)
	};

	const handleJobVIisPublicChange = (e, v) => setJobVIisPublic(v);

	const handleJobVISubmission = async () => {
		await JobService.updateVideoAnsIsPublicFlag(jobId, jobVIisPublic);
		navigate('/edit-proposal/' + job.task_id);
	}

	const width = useWidth();

	return isLoading ? <SuspenseLoader /> :
		<>
			<Dialog open={openModal} onClose={handleCloseModal}>
				<DialogTitle sx={{ fontWeight: 'bold', textAlign: 'center' }}>
					Please consider the following recommendations before recording your video answers
				</DialogTitle>
				<DialogContent>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
				>
					<img
						src={`/videoInt.png`} // Replace with your image URL
						alt="Video Guidelines"
						style={{
							maxWidth: '80%', // Reduce image width
							maxHeight: '300px', // Reduce image height
							height: 'auto',
							marginBottom: '20px',
						}}
					/>
					<Typography variant="body1" component="ol" sx={{ textAlign: 'left' }}>
						<li>
							<b>Lighting:</b> Make sure your face is well-lit and uncovered during your recording.
						</li>
						<li>
							<b>Positioning:</b> Center your face in the frame and ensure it’s visible throughout the entire answer.
						</li>
						<li>
							<b>Technical:</b> Please record using a stable connection and a neutral background.
						</li>
					</Typography>
				</Box>
				</DialogContent>
				<DialogActions 
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Button onClick={handleCloseModal} variant="contained" color="primary">
						Start Interview
					</Button>
				</DialogActions>
			</Dialog>
			<Container sx={{ my: 4 }}>
			<Card sx={{ mt: 3, px: 2, py: 4, width: '100%', maxWidth: '1000px', mx: 'auto' }}>
				{questions?.length ? (
					<Box justifyContent="center" alignItems="center" display="flex" flexDirection="column">
						<VideoInterviewHeader
							title={jobId && job.title ? `${job.title} - Job #${jobId} Video Interview` : 'Video Interview'}
						/>

						{jobId && allAnswered ? (
							<>
								<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="20vh">
									<Typography variant="h4" component="h1">
										All questions have been answered. Thank you!
									</Typography>
									<Typography variant="h5" component="h2">
										Please proceed to submit your video interview. Proposal submission will follow next.
									</Typography>
								</Box>
								<Grid display="flex" alignItems="center">
									<Typography variant="p" sx={{ fontWeight: 'bold', ml: 1 }}>
										Make Video Interview Public:{' '}
									</Typography>
									{jobVIisPublic !== null ? (
										<Switch checked={jobVIisPublic} onChange={handleJobVIisPublicChange} />
									) : (
										<CircularProgress size="1rem" sx={{ mx: 1 }} />
									)}
								</Grid>
								{jobVIisPublic ? <FormHelperText style={{ textAlign: "center" }}>Job's video interview will be shareable and viewable publicly outside the platform after {JobVideoInterviewPrivateDays} days. You can disable if you don't want this to be public !!</FormHelperText> : null}
								<Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: 1 }}>
									<Button
										onClick={handleJobVISubmission}
										variant="contained"
									>
										Submit Video Interview
									</Button>
								</Box>
							</>
						) : (
							<>
								<Box sx={{ width: '100%' }}>
									{width > 550 ? (
										<Stepper nonLinear activeStep={questionsDone + 1} style={{ background: 'none' }}>
											{questions.map((ques, i) => (
												<Step
													key={ques.question_no}
													completed={answeredQIds.includes(ques.id)}
													active={currQIdx === ques.question_no - 1}
													onClick={() => changeQuestion(ques.question_no - 1)}
												>
													<StepButton>
														<span className={ques.question_no === currQIdx + 1 ? 'step-label-active' : ''}>
															Q.{ques.question_no}
														</span>
													</StepButton>
												</Step>
											))}
										</Stepper>
									) : (
										<MobileStepper
											variant="dots"
											position="static"
											steps={questions.length}
											activeStep={currQIdx}
											style={{ background: 'none' }}
											nextButton={
												<Button
													size="small"
													onClick={() => changeQuestion(currQIdx + 1)}
													disabled={currQIdx === 6}
												>
													Next
													<KeyboardArrowRight />
												</Button>
											}
											backButton={
												<Button
													size="small"
													onClick={() => changeQuestion(currQIdx - 1)}
													disabled={currQIdx === 0}
												>
													<KeyboardArrowLeft />
													Back
												</Button>
											}
										></MobileStepper>
									)}
								</Box>
								<Typography paragraph sx={{ textAlign: 'center' }}>
									Q. {questions[currQIdx].question}
								</Typography>
								{!jobId && !showSave && !focusModeOn && recordingAgain && !prevAnsExist && (
									<Button
										onClick={() => changeQuestion(currQIdx + 1)}
										variant="outlined"
										size="small"
										sx={{ mb: 1 }}
									>
										{' '}``
										Skip Question{' '}
									</Button>
								)}
								<Grid
									spacing={0}
									mb={2}
									container
									direction="column"
									alignItems="center"
									justifyContent="center"
									sx={{
										width: '100%',
										height: '60vh',
									}}
								>
									{uploadingPrevAns ? <p>Recorded video is being uploaded, Please review back in some time.</p>
										: (showPrevAns ? <div style={{ margin: 0, padding: 0, display: "flex", flexDirection: "column", width: "100%", height: '60vh' }}>
											<div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginBottom: '10px' }}>
												<p>Your Answer:</p>
												<Button onClick={() => setRecordingAgain(true)} size="small" variant="outlined" sx={{ mt: 1 }}>Record again</Button>
											</div>
											<video
												className="video-interview-video"
												controls
												style={{ maxHeight: "90%" }}
												src={allAnswers[currQAnsId].video_url}
												id={`video-interview-${allAnswers[currQAnsId].id}`}
											></video>
										</div> : <div style={{ margin: 0, padding: 0, display: "flex", flexDirection: "column", width: "100%", height: '90%' }}>
											{!prevAnsExist || focusModeOn || showSave ? null : <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", marginBottom: '10px' }}>
												<Button onClick={() => setRecordingAgain(false)} size="small" variant="outlined" sx={{ mt: 1 }}>Show last recorded Answer</Button>
											</div>}
											<VideoRecorder
												renderDisconnectedView={() => <SuspenseLoader />}
												renderLoadingView={() => <SuspenseLoader />}
												renderActions={(rest) => <VideoActions {...rest} setFocusMode={setFocusModeOn} />}
												renderErrorView={() => <ErrorView />}
												isOnInitially={true}
												countdownTime={0}
												timeLimit={90000}
												showReplayControls={true}
												replayVideoAutoplayAndLoopOff={true}
												isFlipped={false}
												onRecordingComplete={(tempVideoBlob) => {
													onVideoRecordDone(tempVideoBlob);
												}}
												onRec
												key={currQIdx}
											/>
										</div>)
									}
								</Grid>
								<Box sx={{my:1}}>
									<Alert
											severity="warning"
											icon={<ErrorIcon fontSize="small" sx={{ color: "#8B4513" }} />}
											sx={{
													backgroundColor: '#FFF8DC',
													color: '#8B4513',
													border: '1px solid #F4C430',
											}}
											>
											<AlertTitle sx={{ fontWeight: 'bold', fontSize: '14px' }}>
													No Face Detected
											</AlertTitle>
											<Typography variant="body2" sx={{fontSize:"12px"}}>
											Our system couldn’t detect your face in the video. Please ensure your face is visible and re-record this response.
											</Typography>
									</Alert>
								</Box>
								{showSave ? (
									<Button
										onClick={saveVideo}
										variant="contained"
										startIcon={saving && <CircularProgress size="1rem" />}
									>
										Save & Continue{' '}
									</Button>
								) : null}
							</>
						)}
					</Box>
				) : (
					<SuspenseLoader />
				)}
			</Card>
			{
				showNavButtons && (
					<ButtonWrapper
						isSkipable
						isPrevStep
						prevCB={subStep}
						nextCB={skipNext}
						skipCB={skipNext}
						isDisabled={!allAnswered}
						refs={{ skip: skipRef }}
						variants={{ skip: skipBtnVariant }}
					/>
				)
			}
			</Container >
		</>
};

export default VideoInterview;
