export const env = process.env.REACT_APP_NODE_ENV;
export const ClientToken = process.env.REACT_APP_CLIENT_TOKEN;

// Function to get API Prefix based on the host
const getAPIPrefix = () => {
    let host = window.location.host;
    switch (host) {
        case "platform.dev.mrkt365.com":
            return process.env.REACT_APP_DEV_API_PREFIX;
        case "qa-platform.dev.mrkt365.com":
            return process.env.REACT_APP_QA_API_PREFIX;
        case ".mrkt365.com": 
        case "platform.mrkt365.com":
          return process.env.REACT_APP_PROD_HOST
        default:
          return process.env.REACT_APP_DEV_API_PREFIX;;
    }
};

const apiPrefix = getAPIPrefix();

// Define URLs
const localHost = process.env.REACT_APP_NODE_ENV === 'development' ? 
process.env.REACT_APP_DEV_HOST : process.env.REACT_APP_PROD_HOST;
const API_URL = `https://${apiPrefix}.mrkt365.com/`;

// Define constants for Current URL
export const CURRENT_URL = `${window.location.origin}/`;

// Define Endpoints for different services
export const DEV_MRKT365User_Endpoint = `${localHost}mrkt365user/api/v1/`;
export const MRKT365User_Endpoint = `${API_URL}mrkt365user/api/v1/`;

export const DEV_MRKT365Vendor_Endpoint = `${localHost}mrkt365vendor/api/v1/`;
export const MRKT365Vendor_Endpoint = `${API_URL}mrkt365vendor/api/v1/`;

export const DEV_MRKT365Project_Endpoint = `${localHost}mrkt365project/api/v1/`;
export const MRKT365Project_Endpoint = `${API_URL}mrkt365project/api/v1/`;
export const DEV_MRKT365Projectv2_Endpoint = `${localHost}mrkt365project/api/v2/`;
export const MRKT365Projectv2_Endpoint = `${API_URL}mrkt365project/api/v2/`;

export const DEV_MRKT365Payment_Endpoint = `${localHost}mrkt365payment/api/v1/`;
export const MRKT365Payment_Endpoint = `${API_URL}mrkt365payment/api/v1/`;

export const DEV_MRKT365SEO3p_Endpoint = `${localHost}mrkt365seo3p/api/v1/`;
export const MRKT365SEO3p_Endpoint = `${API_URL}mrkt365seo3p/api/v1/`;

export const DEV_MRKT365AI_Endpoint = `${localHost}mrkt365ai/api/v1/`;
export const MRKT365AI_Endpoint = `${API_URL}mrkt365ai/api/v1/`;

// Export user-specific endpoints based on environment
export const userEndpoint =
  env === "development" ? DEV_MRKT365User_Endpoint : MRKT365User_Endpoint;
export const vendorEndpoint =
  env === "development" ? DEV_MRKT365Vendor_Endpoint : MRKT365Vendor_Endpoint;
export const projectEndpoint =
  env === "development" ? DEV_MRKT365Project_Endpoint : MRKT365Project_Endpoint;
export const projectV2Endpoint =
  env === "development"
    ? DEV_MRKT365Projectv2_Endpoint
    : MRKT365Projectv2_Endpoint;
export const paymentEndpoint =
  env === "development" ? DEV_MRKT365Payment_Endpoint : MRKT365Payment_Endpoint;
export const seo3pEndpoint =
  env === "development" ? DEV_MRKT365SEO3p_Endpoint : MRKT365SEO3p_Endpoint;
export const aiEndpoint =
  env === "development" ? DEV_MRKT365AI_Endpoint : MRKT365AI_Endpoint;

// WebSocket Endpoints
export const projectWebSocketEndpoint = `wss://${apiPrefix}.mrkt365.com/mrkt365project/ws/`;
export const userWebSocketEndpoint = `wss://${apiPrefix}.mrkt365.com/mrkt365user/ws/`;
export const chatWebsocketUrl = `${projectWebSocketEndpoint}chat/`;
export const unreadChatsWebsocketUrl = `${projectWebSocketEndpoint}unread_chats_count/`;
export const chatDashboardWebsocketUrl = `${projectWebSocketEndpoint}chat_dashboard/`;

// Production flag
export const trueProductionFlag = apiPrefix === "api";

// STUN server configuration
export const StunServerIPnHost =
  process.env.REACT_APP_STUN_SERVER_IP_HOST;

