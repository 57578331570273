import { REPORT_START_CALL } from "./constants";

function meetingCallStore(
    state = { newOutgoingCallStartActionTaken: false, otherUserId: null, otherUserName: "", otherUserAvatarUrl: "" },
    action
) {
    // console.log("inside meetingCallStore reducer");
    // console.log()
    switch (action.type) {
        case REPORT_START_CALL:
            return {
                ...state,
                newOutgoingCallStartActionTaken: true,
                otherUserId: action.payload.otherUserId,
                otherUserName: action.payload.otherUserName,
                otherUserAvatarUrl: action.payload.otherUserAvatarUrl,
            };
        default:
            return state;
    }
}

export { meetingCallStore };
