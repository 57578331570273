import React from 'react';
import { Typography, Tooltip, Stepper, Step, StepButton } from '@mui/material';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import ChatIcon from '@mui/icons-material/Chat';


const VideoInterviewHeader = ({ title }) => {
	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Typography variant="h4" sx={{ mr: 1 }}>
				{title ? title : 'Video Interview'}
			</Typography>
			<Tooltip
				arrow
				componentsProps={{
					tooltip: {
						sx: {
							backgroundColor: 'white',
							color: 'black',
						},
					},
				}}
				title={
					<>
						<p>Click on any number to jump to that question.</p>
						<Stepper orientation="vertical">
							<Step active={true}>
								<StepButton>Active Question.</StepButton>
							</Step>
							<Step>
								<StepButton>
									<p style={{ color: '#223354' }}>Unanswered Question.</p>
								</StepButton>
							</Step>
							<Step completed={true}>
								<StepButton>Answered Question.</StepButton>
							</Step>
							<Step
								StepIconComponent={ChatIcon}
							>
								<StepButton StepIconComponent={ChatIcon}>Attention Needed.</StepButton>
							</Step>

						</Stepper>
					</>
				}
			>
				<InfoTwoToneIcon />
			</Tooltip>
		</div>
	);
};

export default VideoInterviewHeader;
