import React, { useState, useEffect, useRef } from "react";
import { useWebSocket } from "react-use-websocket/dist/lib/use-websocket";
import { Button } from "@mui/material";
import { projectWebSocketEndpoint, StunServerIPnHost } from "src/mrkt365config";

const pcConfig = {
    iceServers: [
        {
            urls: "stun:" + StunServerIPnHost,
        },
        {
            urls: "turn:" + StunServerIPnHost,
            username: "mrkt365",
            credential: "mrkt365",
        },
    ],
};

const VideoCallScreen = (props) => {
    const { connected, setConnected, otherUserId, userId, callStatus } = props;
    const selfVideoRef = useRef(null);
    const videoRef = useRef(null);
    const audioSender = useRef(null);
    const videoSender = useRef(null);
    const audioReceiver = useRef(null);
    const videoReceiver = useRef(null);
    const peerConnection1 = useRef(null);
    const peerConnection2 = useRef(null);
    const iceCandidates1 = useRef([]);
    const iceCandidates2 = useRef([]);
    const iceCandidatesSent1 = useRef(false);
    const iceCandidatesSent2 = useRef(false);
    const [iceCandidatesCanPass1, setIceCandidatesCanPass1] = useState(false);
    const [iceCandidatesGathered1, setIceCandidatesGathered1] = useState(false);
    const [iceCandidatesCanPass2, setIceCandidatesCanPass2] = useState(false);
    const [iceCandidatesGathered2, setIceCandidatesGathered2] = useState(false);
    const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
        projectWebSocketEndpoint + "ongoing_video_call/" + userId + "/" + otherUserId
    );

    const sendIceCandidates1 = () => {
        sendMessage(
            JSON.stringify({ connection_no: 1, message: { type: "candidate", iceCandidates: iceCandidates1.current } })
        );
    };

    const sendIceCandidates2 = () => {
        sendMessage(
            JSON.stringify({ connection_no: 2, message: { type: "candidate", iceCandidates: iceCandidates2.current } })
        );
    };

    const onIceCandidate1 = (event) => {
        if (event.candidate) {
            iceCandidates1.current.push({
                type: "candidate",
                label: event.candidate.sdpMLineIndex,
                id: event.candidate.sdpMid,
                candidate: event.candidate.candidate,
            });
            console.log(iceCandidates1);
        } else {
            setIceCandidatesGathered1(true);
        }
    };

    useEffect(() => {
        console.log(
            "1 ice connection status: iceCandidatesCanPass1, iceCandidatesGathere, iceCandidatesSent1",
            iceCandidatesCanPass1,
            iceCandidatesGathered1,
            !iceCandidatesSent1.current
        );
        if (iceCandidatesCanPass1 && iceCandidatesGathered1 && !iceCandidatesSent1.current) {
            sendIceCandidates1();
            iceCandidatesSent1.current = true;
        }
    }, [iceCandidatesCanPass1, iceCandidatesGathered1]);

    const onIceCandidate2 = (event) => {
        if (event.candidate) {
            iceCandidates2.current.push({
                type: "candidate",
                label: event.candidate.sdpMLineIndex,
                id: event.candidate.sdpMid,
                candidate: event.candidate.candidate,
            });
        } else {
            setIceCandidatesGathered2(true);
        }
    };

    useEffect(() => {
        if (iceCandidatesCanPass2 && iceCandidatesGathered2 && !iceCandidatesSent2.current) {
            sendIceCandidates2();
            iceCandidatesSent2.current = true;
        }
    }, [iceCandidatesCanPass2, iceCandidatesGathered2]);

    useEffect(() => console.log(peerConnection1.current), [peerConnection1]);

    const startCall = (connection_no = 1) => {
        // create peer connection and set up event listeners
        // if (connection_no === 2) sendMessage(JSON.stringify({ connection_no: 1, message: { type: "candidate", 'iceCandidates': iceCandidates1.current } }))
        const pc = new RTCPeerConnection(pcConfig);
        if (connection_no === 1) peerConnection1.current = pc;
        else peerConnection2.current = pc;
        pc.onicecandidate = connection_no === 1 ? onIceCandidate1 : onIceCandidate2;
        pc.ontrack = (stream) => {
            videoRef.current.srcObject = stream.streams[0];
            console.log("window.postMessage(" + "senders ontrack");
        };
        navigator.mediaDevices
            .getUserMedia({ audio: true, video: true })
            .then((stream) => {
                console.log("window.postMessage(" + "window.postMessage(" + stream, "stream");
                selfVideoRef.current.srcObject = stream;
                audioSender.current = pc.addTransceiver(stream.getAudioTracks()[0]);
                videoSender.current = pc.addTransceiver(stream.getVideoTracks()[0]);
                // pc.addTrack(stream.getAudioTracks()[0], stream)
                // pc.addTrack(stream.getVideoTracks()[0], stream)
            })
            .catch((error) => console.error(error));
        pc.onconnectionstatechange = () => {
            if (pc.connectionState !== "connected") return;
            console.log(
                "connectionstatechanged",
                pc.connectionState,
                pc,
                videoSender.current,
                typeof videoSender.current,
                "connection_no:",
                connection_no
            );
            console.log(pc.getTransceivers());
        };
        pc.createOffer({ offerToReceiveAudio: true, offerToReceiveVideo: true })
            .then((offer_) => {
                pc.setLocalDescription(offer_);
                console.log("sending offer...");
                sendMessage(JSON.stringify({ connection_no: connection_no, message: offer_ }));
            })
            .catch((error) => console.error(error));

        pc.onicegatheringstatechange = async () => {
            console.log(pc.connectionState, "icegatheringstatechanged");
        };
        pc.onicecandidateerror = (ev) => console.error("onicecandidateerror", ev);
        pc.oniceconnectionstatechange = (ev) => console.log("iceconnectionstatechanged", ev);
        pc.onsignalingstatechange = (ev) => console.log("signalingstatechanged", ev);
    };

    const endCall = () => {
        // close peer connection and reset state
        peerConnection1.current.close();
        peerConnection1.current = null;
        peerConnection2.current.close();
        peerConnection2.current = null;
        setConnected(false);
    };

    useEffect(() => {
        if (!lastMessage) return;
        const data = JSON.parse(lastMessage.data);
        const connection_no = data.connection_no;
        const message = data.message;
        const peerConnection = connection_no === 1 ? peerConnection1.current : peerConnection2.current;
        if (message.type === "offer") {
            const pc = new RTCPeerConnection(pcConfig);
            pc.setRemoteDescription(new RTCSessionDescription(message))
                .then(() => navigator.mediaDevices.getUserMedia({ audio: true, video: true }))
                .then((stream) => {
                    selfVideoRef.current.srcObject = stream;
                    audioSender.current = pc.addTrack(stream.getAudioTracks()[0], stream);
                    videoSender.current = pc.addTrack(stream.getVideoTracks()[0], stream);
                })
                .then(async () => {
                    if (connection_no === 1) peerConnection1.current = pc;
                    else peerConnection2.current = pc;
                    pc.onicecandidate = connection_no === 1 ? onIceCandidate1 : onIceCandidate2;
                    pc.ontrack = (stream) => {
                        videoRef.current.srcObject = stream.streams[0];
                        audioReceiver.current = stream.getAudioTracks();
                        videoReceiver.current = stream.getVideoTracks();
                        setConnected(true);
                    };
                    console.log(pc);
                    pc.onconnectionstatechange = () => {
                        if (pc.connectionState !== "connected") return;
                        // console.log("connectionstatechanged", pc.connectionState, pc, "connection_no:", connection_no);
                        // console.log(pc.getTransceivers());
                    };
                    pc.createAnswer({ offerToReceiveAudio: true, offerToReceiveVideo: true })
                        .then(async (answer_) => {
                            await pc.setLocalDescription(answer_);
                            // console.log("sending answer...");
                            sendMessage(JSON.stringify({ connection_no: connection_no, message: answer_ }));
                            sendMessage(
                                JSON.stringify({
                                    connection_no: connection_no,
                                    message: { type: "is_accepting_ice_candidates" },
                                })
                            );
                            if (connection_no === 1) startCall(2);
                        })
                        .catch((error) => console.error(error));
                })
                .catch((error) => console.error(error));
        } else if (message.type === "answer") {
            peerConnection.setRemoteDescription(new RTCSessionDescription(message));
        } else if (message.type === "candidate") {
            message.iceCandidates.forEach((v) => {
                const candidate = new RTCIceCandidate({ sdpMLineIndex: v.label, candidate: v.candidate });
                peerConnection.addIceCandidate(candidate);
            });
        } else if (message.type === "is_accepting_ice_candidates") {
            connection_no === 1 ? setIceCandidatesCanPass1(true) : setIceCandidatesCanPass2(true);
        }
    }, [lastMessage]);

    useEffect(() => {
        if (callStatus.status === "ongoing_call_initiator") startCall();
    }, [callStatus]);

    return (
        <div>
            <p>Your video:</p>
            <video ref={selfVideoRef} autoPlay style={{ border: "2px solid black" }} muted />
            <p>Other person video</p>
            <video ref={videoRef} autoPlay style={{ border: "2px solid black" }} />
            <br />
            {!connected && <Button onClick={endCall}>End call</Button>}
        </div>
    );
};

export default VideoCallScreen;
